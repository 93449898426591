import React from "react";
import "./global.css";
import styled, { css } from "styled-components";
import logo from "./logo512.png";

const ClearFlex = css`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-decoration: none;
`;

const Site = {
  Container: styled.div`
    width: 100vw;
    min-height: 100vh;
    height: 100%;
    ${ClearFlex}
    justify-content: flex-start;
    background-color: var(--color-darkest);
    color: var(--color-light);
    scroll-snap-type: y mandatory;
    scroll-behavior: smooth;
    overflow-y: auto;
    overflow-x: hidden;
  `,

  Wrapper: styled.main`
    ${ClearFlex}
    gap: 20px;
    font-size: calc(0.7vw + 0.7vh);
    h1,
    h2,
    h3 {
      ${ClearFlex}
    }
    h1 {
      font-size: calc(1.8vw + 1.8vh);
      color: var(--color-light);
      text-transform: uppercase;
    }
    h3,
    h2 {
      color: var(--color-lightest);
      padding: 10px;
    }
    h2 {
      font-size: calc(0.9vw + 0.9vh);
    }
    h3 {
      font-size: calc(0.8vw + 0.8vh);
    }
    @media (max-width: 800px) {
      justify-content: flex-start;
      padding-top: 10px;
      gap: 15px;
      font-size: calc(1.2vw + 1.2vh);
      h1,
      h2,
      h3 {
        padding: 2px;
      }
      h1 {
        font-size: calc(2vw + 2vh);
      }
      h2 {
        font-size: calc(1.8vw + 1.8vh);
      }
      h3 {
        font-size: calc(1.8vw + 1.8vh);
      }
    }
  `,

  Section: styled.div`
    ${ClearFlex}
    gap: 20px;
    &.mainSection {
      flex-direction: row;
      gap: 80px;
      @media (max-width: 800px) {
        flex-direction: column-reverse;
        gap: 30px;
      }
    }

    span {
      ${ClearFlex};
      &.navlogo {
        flex-direction: row;
        gap: 10px;
        font-size: calc(2vw + 2vh);
        font-weight: bold;
        padding-top: 10px;
        @media (max-width: 800px) {
          font-size: calc(3.5vw + 3.5vh);
          padding-top: 0;
        }
      }
      &.mainContentSpan {
        max-width: 100%;
        gap: 10px;
        @media (max-width: 800px) {
          max-width: 100vw;
          gap: 10px;
          padding: 0;
        }
      }
    }

    img {
      &.small {
        border-radius: 50%;
        height: calc(1.6vw + 1.6vh);
        @media (max-width: 800px) {
          height: 8vmin;
        }
      }
      &.big {
        max-height: 50vh;
        max-width: 70%;
        border-radius: 15px;
        box-shadow: -5px -5px 15px var(--solana-green),
          5px 5px 15px var(--solana-pink);
      }
    }
  `,

  Linkout: styled.a`
    ${ClearFlex}
    position: fixed;
    top: 20px;
    right: 20px;
    @media (max-width: 800px) {
      position: relative;
      top: auto;
      right: auto;
      margin-inline: auto;
    }
  `,

  Button: styled.button`
    ${ClearFlex}
    padding: 20px 40px;
    border-radius: 15px;
    border: 2px solid var(--color-primary);
    background: none;
    background-color: var(--color-dark);
    color: var(--color-light);
    text-transform: uppercase;
    font-size: inherit;
    letter-spacing: 1px;
    font-weight: bold;
    cursor: pointer;
    &:hover {
      background-color: var(--color-primary);
      border: 2px solid var(--color-lightest);
      color: var(--color-lightest);
    }
    &:active {
      background-color: var(--color-darkest);
      border: 2px solid var(--color-primary);
      color: var(--color-lightest);
    }
  `,

  Footer: styled.div`
    ${ClearFlex}
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    padding: 5px;
    margin-top: auto;
    text-wrap: no-wrap;
    white-space: nowrap;
    font-size: calc(0.5vw + 0.5vh);
    @media (max-width: 800px) {
      font-size: calc(1vw + 1vh);
    }
  `,
};

const List = {
  Items: styled.ul`
    ${ClearFlex}
    list-style-type: circle;
    &.stats {
      list-style-type: none;
    }
    &.roadmap {
      padding-left: 40px;
      padding-left: 5%;
      padding-bottom: 20px;
    }
    @media (max-width: 800px) {
      width: 95%;
      letter-spacing: 1px;
      code {
        letter-spacing: normal;
      }
      &.roadmap {
        padding-bottom: 0;
      }
    }
  `,

  Item: styled.li`
    width: 100%;
    align-items: left;
    text-align: left;
    color: var(--color-lightest);
    text-wrap: no-wrap;
    white-space: nowrap;
    margin-bottom: 5px;
    a {
      color: var(--solana-blue);
    }
    @media (max-width: 800px) {
      text-wrap: no-wrap;
      white-space: normal;
    }
  `,
};

function Stats() {
  return (
    <List.Items className="stats">
      <List.Item>
        Token Address:{" "}
        <a
          href="https://solscan.io/token/HVbV67tKMtdW8gPQURR3vKs6ApahGhN2TDFDnmhF8dRc"
          rel="noreferrer"
          target="_blank"
        >
          <code>HVbV67tKMtdW8gPQURR3vKs6ApahGhN2TDFDnmhF8dRc</code>
        </a>
      </List.Item>
      <List.Item>
        Market ID:{" "}
        <a
          href="https://solscan.io/account/2XQ5L4uAgQV3QAHhcCQYvtvhojKBj1Z1H8EL9cRnyZfU"
          rel="noreferrer"
          target="_blank"
        >
          <code>2XQ5L4uAgQV3QAHhcCQYvtvhojKBj1Z1H8EL9cRnyZfU</code>
        </a>
      </List.Item>
      <List.Item>
        Raydium AMM ID:{" "}
        <a
          href="https://solscan.io/account/H6cLuS7yEzo8eNqyAuSgiCzGhywdru4vZBGuA8my2Fjy"
          rel="noreferrer"
          target="_blank"
        >
          <code>H6cLuS7yEzo8eNqyAuSgiCzGhywdru4vZBGuA8my2Fjy</code>
        </a>
      </List.Item>
      <List.Item>
        CMNDR-SOL (Raydium):{" "}
        <a
          href="https://birdeye.so/token/HVbV67tKMtdW8gPQURR3vKs6ApahGhN2TDFDnmhF8dRc/H6cLuS7yEzo8eNqyAuSgiCzGhywdru4vZBGuA8my2Fjy?chain=solana"
          rel="noreferrer"
          target="_blank"
        >
          🐦birdeye
        </a>
      </List.Item>
      <List.Item>
        Airdrop Wallet (20%):{" "}
        <a
          href="https://solscan.io/account/2WyBx23qxohxyC7ADj4nhyAnTfSkgkaTzqPCvVyXTfRD"
          rel="noreferrer"
          target="_blank"
        >
          <code>2WyBx23qxohxyC7ADj4nhyAnTfSkgkaTzqPCvVyXTfRD</code>
        </a>
      </List.Item>
    </List.Items>
  );
}

function Roadmap() {
  return (
    <List.Items className="roadmap">
      <List.Item title="There are other Solana Dog tokens, but only ths one is a genuine project by a single dev who is also a dog who bites.">
        Create $CMNDR & Launch Website ✔
      </List.Item>
      <List.Item title="Announcements & Updates will only be here because I am a dog can only code React.">
        F*ck Twitter & Discord ✔
      </List.Item>
      <List.Item title="I am out of funds, please help me and I will send you a chunk of $CMNDR">
        Create Raydium Market & Reserve 20% Supply ✔
      </List.Item>
      <List.Item title="Snapshot Taken....just kidding I'm a dog.">
        Provide 80% of supply + big drop to current holders to compensate
        (IN-PROGRESS)
      </List.Item>
      <List.Item title="Amount of tokens per holder per airdrop will be equal to amount of times Dog has done a Bite; i.e. currently '24'">
        Hodlrdrop every time Dog Bite someone until fully diluted.
      </List.Item>
      <List.Item title="I am Biting Dog, you do the maths.">❓❓❓</List.Item>
      <List.Item title="Else Bite.">Moon 🚀</List.Item>
    </List.Items>
  );
}

function App() {
  return (
    <Site.Container>
      <Site.Wrapper>
        <Site.Section>
          <span className="navlogo">
            <img src={logo} alt="cmndr logo small" className="small" />
            <>$CMNDR</>
          </span>
          <h2>Biting Dog</h2>
          <h1>here and ready to bite.</h1>
        </Site.Section>

        <Site.Section className="mainSection">
          <span className="mainContentSpan">
            <h3>Solana Dog Token (IYKYK)</h3>
            {/* <FAQ /> */}
            <Stats />
            <h3>Roadmap</h3>
            <Roadmap />
          </span>
          <Site.Linkout
            href="https://raydium.io/swap/?inputCurrency=sol&outputCurrency=HVbV67tKMtdW8gPQURR3vKs6ApahGhN2TDFDnmhF8dRc&fixed=in"
            rel="noreferrer"
            target="_blank"
          >
            <Site.Button>bite on raydium</Site.Button>
          </Site.Linkout>
          <img src={logo} alt="cmndr logo" className="big" />
        </Site.Section>
      </Site.Wrapper>
      <Site.Footer>
        <b>DISCLAIMER:</b> &nbsp; I am a dog. Send financial advice to
        bitingdog@cmndr.xyz
      </Site.Footer>
    </Site.Container>
  );
}

export default App;
